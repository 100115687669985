socialCounters = (function () {
    var self = {};

    self.options = {
        url: encodeURIComponent(window.location.href.replace(window.location.hash, '')),
        title: encodeURIComponent(document.title),
        pollTimeout: 10000,
        pollTimes: 1
    };

    self.facebook = {
        popupUrl: 'http://www.facebook.com/sharer/sharer.php?u=' + self.options.url,
        popupWidth: 600,
        popupHeight: 500,
        cssPrefix: "fb"
    };

    self.vkontakte = {
        popupUrl: 'http://vk.com/share.php?url=' + self.options.url + '&title=' + self.options.title,
        popupWidth: 650,
        popupHeight: 550,
        cssPrefix: "vk"
    };

    self.twitter = {
        popupUrl: 'http://twitter.com/intent/tweet?url=' + self.options.url + '&text=' + self.options.title,
        popupWidth: 600,
        popupHeight: 450,
        cssPrefix: "tw"
    };

    self.googlePlus = {
        popupUrl: 'https://plus.google.com/share?url=' + self.options.url,
        popupWidth: 500,
        popupHeight: 450,
        cssPrefix: "gp"
    };

    self.ok = {
        popupUrl: 'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=' + self.options.url + '&st.comments=' + self.options.title,
        popupWidth: 700,
        popupHeight: 500,
        cssPrefix: "ok"
    };

    self.mail = {
        popupUrl: 'http://connect.mail.ru/share?url=' + self.options.url + '&title=' + self.options.title,
        popupWidth: 450,
        popupHeight: 320,
        cssPrefix: "mail"
    };

	self.facebook_a = {
        popupUrl: 'http://www.facebook.com/sharer/sharer.php?u=' + self.options.url,
        popupWidth: 600,
        popupHeight: 500,
        cssPrefix: "fba"
    };
	
	self.twitter_a = {
        popupUrl: 'http://twitter.com/intent/tweet?url=' + self.options.url + '&text=' + self.options.title,
        popupWidth: 600,
        popupHeight: 450,
        cssPrefix: "twa"
    };
	
    self.telegram_a = {
        popupUrl: 'https://t.me/share/url?url=' + self.options.url + '&text=' + self.options.title,
        popupWidth: 450,
        popupHeight: 320,
        cssPrefix: "tga"
    };


    self.methods = {
        init: function () {
            self.methods.bindClick(self.facebook.cssPrefix);
            self.methods.bindClick(self.vkontakte.cssPrefix);
            self.methods.bindClick(self.twitter.cssPrefix);
            self.methods.bindClick(self.ok.cssPrefix);
            self.methods.bindClick(self.mail.cssPrefix);
            self.methods.bindClick(self.googlePlus.cssPrefix);
			
            self.methods.bindClick(self.facebook_a.cssPrefix);
            self.methods.bindClick(self.twitter_a.cssPrefix);
            self.methods.bindClick(self.telegram_a.cssPrefix);

            self.methods.pollStat();
            // self.methods.updateCounters(); // To be removed later
        },
        getSocialNetwork: function (socialNetwork) {
            switch (socialNetwork) {
                case self.facebook.cssPrefix:
                    return self.facebook;
                case self.vkontakte.cssPrefix:
                    return self.vkontakte;
                case self.twitter.cssPrefix:
                    return self.twitter;
                case self.googlePlus.cssPrefix:
                    return self.googlePlus;
                case self.ok.cssPrefix:
                    return self.ok;
                case self.mail.cssPrefix:
                    return self.mail;
					
                case self.facebook_a.cssPrefix:
                    return self.facebook_a;
                case self.twitter_a.cssPrefix:
                    return self.twitter_a;
                case self.telegram_a.cssPrefix:
                    return self.telegram_a;
            }

            return null;
        },
        bindClick: function (socialPrefix) {
            $(".social__" + socialPrefix).click(function () {
                //remember element
                self.methods.currentButton = $(this);
                // self.methods.updateCounters();
                self.methods.openWindow();
                self.methods.pollStat();

                return false;
            });
        },
        updateCounters: function () {
            if($('.share').length)
            {
                $.getJSON($('.share').attr("href"), function (data) {
                    if(data.fb > 0) $(".social__fb").text(data.fb);
                    if(data.vk > 0) $(".social__vk").text(data.vk);
                    if(data.tw > 0) $(".social__tw").text(data.tw);
                    if(data.ok > 0) $(".social__ok").text(data.ok);
                    if(data.mail > 0) $(".social__mail").text(data.mail);
                    if(data.gp > 0) $(".social__gp").text(data.gp);
                    if(window.console) console.log('Socials update: ', data);
                    // return true;
                });
            }
            // return false;
        },
        openWindow: function () {
            var socialObject = self.methods.getSocialNetwork(this.currentButton.attr("data-sn"));
            var w = window.open(
                socialObject.popupUrl, 
                "ShareWindow", "width=" + socialObject.popupWidth +
               ",height=" + socialObject.popupHeight +
               ",resizable=yes,scrollbars=yes,status=yes,left=" + (screen.width / 2 - socialObject.popupWidth / 2) +
               ",top=" + (screen.height / 2 - socialObject.popupHeight / 2)
               );
            w.focus();
        },
        pollStat: function () {
            var pollCounter = 0;

            var intervalID = setInterval(function () {
                if (pollCounter == self.options.pollTimes) {
                    clearInterval(intervalID);
                }
                else {
                    pollCounter++;
                    self.methods.updateCounters();
                }
            }, self.options.pollTimeout);
        },
        currentButton: null
    };

    return {
        init: function () {
            return self.methods.init();
        },
        refreshCounters: function () {
            // self.methods.currentButton = $("a[data-sn]:first");
            return self.methods.updateCounters();
        }
    };
})();

$(function () { socialCounters.init(); });
