(function()
{
    var mobileSocButton = document.getElementById('mobileSocButton'),
    mobileSoc = document.getElementById('mobileSoc');
    mobileSocButton.addEventListener('click', function()
    {
	if(!mobileSoc.classList.contains('is-active'))
	{
            mobileSocButton.classList.add('is-active');
            mobileSoc.classList.add('is-active');
        }
	else
	{
            mobileSocButton.classList.remove('is-active');
            mobileSoc.classList.remove('is-active');
        }
    });
})();
