$(document).ready(function() {

// Если хотим только внутри статьи - то так
//   $(".article__summary a[href^=http]").each(function()
//
   $("a[href^=http]").each(function(){
      var excludes = [
         'fraza.ua',
         'fraza.com'
      ];
      for(i=0; i<excludes.length; i++) {
         if(this.href.indexOf(excludes[i]) != -1) {
            return true; // continue each() with next link
         }
      }
      if(this.href.indexOf(location.hostname) == -1)
	  {
           // $(this).click(function() { return true; }); 
           $(this).attr({
               target: "_blank"
               // title: ""
           });
           // $(this).click(); // trigger it
      }
   })
});