$( document ).ready(
function()
{
	$('.multitabs-tabs .mtab').click(
		function()
		{
			console.log('11a');
			var $multitabsBlock = $(this).closest('.multitabs-block'),
				targetSelector = $(this).data('tab-target');
			$multitabsBlock.find('.multitabs-tabs .mtab').removeClass('mtab-active');
			$multitabsBlock.find('.multitabs-content .mtab-content').removeClass('mtab-content-active');
			$(this).addClass('mtab-active');
			$(targetSelector).addClass('mtab-content-active');
		});
});

