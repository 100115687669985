$(document).ready(function() {
    var ddParent = $('#navHugeDropdown');
    var ddButton = $('.nav__dropdown__button');
    var ddContent = $('.nav__dropdown__content');

    function removeDropdown() {
        ddButton.removeClass('is-active');
        ddContent.removeClass('is-active');
        ddContent.slideUp(200);
    }

    ddButton.click(function() {
        if(!ddButton.hasClass('is-active')) {
            ddButton.addClass('is-active');
            ddContent.addClass('is-active');
            ddContent.slideDown(200);
        } else {
            removeDropdown();
        }
    });

    //hide if click outside of opened dropdown
    $(window).click(function() {
        if(ddButton.hasClass('is-active')) {
            removeDropdown();
        }
    });
    ddParent.click(function(event){
        event.stopPropagation();
    });
});
    