$( document ).ready(
function()
{
	$(".scaledvideo-container iframe").filter(
	function(i)
	{
		if('undefined' !== typeof $(this).attr("width") && 'undefined' !== $(this).attr("height") && parseInt($(this).attr("height")) > parseInt($(this).attr("width")))
		{
			return 1;
		}
	}).each(
	function( index )
	{
		var thisIframe = $(this);
		var iframeHeight = parseInt(thisIframe.attr("height"));
		var iframeWidth = parseInt(thisIframe.attr("width"));
		var parentDiv = thisIframe.parent('.scaledvideo-container');
		if(parentDiv.width() < iframeWidth)
		{
			iframeWidth = parentDiv.width();
			iframeHeight = Math.round(parseInt(thisIframe.attr("height")) / parseInt(thisIframe.attr("width")) * iframeWidth);
		}
		parentDiv.addClass('fr-video-change-on-resize', 1);
		thisIframe.css({'height': iframeHeight + 'px', 'width': iframeWidth + 'px', 'position': 'relative'});
		parentDiv.css({'padding': 'unset', 'height': 'unset'});
		$(window).resize(function()
		{
			$('.fr-video-change-on-resize').each(function()
			{
				var thisDiv = $(this);
				var iframe = thisDiv.find('iframe.scaledvideo');
				var iframeWidthVal = parseInt(iframe.attr("width"));
				var iframeHeightVal = parseInt(iframe.attr("height"));
				var thisIframeRatio = iframeHeightVal / iframeWidthVal;
				if(thisDiv.width() < iframe.width())
				{
					iframe.css({'height': Math.round(thisDiv.width() * thisIframeRatio) + 'px', 'width': thisDiv.width() + 'px'});
				}
				if(thisDiv.width() > iframe.width() && iframe.width() < iframeWidthVal)
				{
					iframe.css({'height': Math.round(iframeWidthVal * thisIframeRatio) + 'px', 'width': iframeWidthVal + 'px'});
				}
			});
		});
	});
});

