document.addEventListener('copy', (event) =>
{
	//var maxLength = 300;
	var text = document.getSelection().toString();
	var textLength = text.length;
	var pageLink = "\n\n" + 'Подробности читайте на Фразе: ' + window.location.href;
	//text = text.substring(0, maxLength);
	//if(textLength > maxLength)
	//{
	//	text += '...';
	//}
	event.clipboardData.setData('text', text + pageLink);
	event.preventDefault();
});

