(function() {
    var mobileMenuButton = document.getElementById('mobileMenuButton'),
        mobileMenu = document.getElementById('mobileMenu'),
        bottomGradient = mobileMenu.getElementsByClassName('mobile-menu__gradient-bottom')[0],
        body = document.body,
        navTop = document.getElementById('navTop'),
        header = document.getElementById('header');
        

    mobileMenuButton.addEventListener('click', function() {
        if(!mobileMenu.classList.contains('is-active')) {
            mobileMenuButton.classList.add('is-active');
            mobileMenu.classList.add('is-active');
            setTimeout(function() {
                bottomGradient.classList.add('is-active');
            }, 450);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            body.classList.add('no-scroll');
            window.onscroll = function () { window.scrollTo(0, 0); };
        } else {
            mobileMenuButton.classList.remove('is-active');
            mobileMenu.classList.remove('is-active');
            bottomGradient.classList.remove('is-active');
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            body.classList.remove('no-scroll');
            window.onscroll = function () { return true; };
        }
    });
})();